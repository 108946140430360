import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import { Heading, P, Span } from "../../../packages/Typography/src";
import Flex from "../../../packages/Flex/src";
import Link from "../../../packages/Link/src";
import Icon from "../../../packages/Icon/src";

var CalloutPattern = function CalloutPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Div, {
    bg: "primary",
    color: "white"
  }, React.createElement(Container, {
    size: "sm",
    py: "5rem"
  }, React.createElement(Heading, {
    as: "h2",
    size: "sm",
    color: "white",
    mt: "0",
    mb: "1.5rem",
    muted: true,
    textTransform: "uppercase"
  }, "Our mission"), React.createElement(P, {
    size: "xxl",
    fontWeight: "600",
    lineHeight: "dense"
  }, "We work together with researchers and educators to add human narration to these stories and to bridge the gap between scientists and citizens."), React.createElement(Link, {
    href: "/",
    variant: "stealth"
  }, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Span, {
    size: "md"
  }, "Read more"), React.createElement(Icon, {
    name: "chevron_right"
  }))))));
};

export default CalloutPattern;